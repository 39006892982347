import React, { useContext } from "react";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Container from "@material-ui/core/Container";
import Link from "@material-ui/core/Link";
import {
  Button,
  Card,
  CardContent,
  createTheme,
  FormControl,
  Grid,
  Hidden,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  ThemeProvider,
  withStyles,
} from "@material-ui/core";
import ShoppingCartRoundedIcon from "@material-ui/icons/ShoppingCartRounded";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination } from "swiper";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.scss";
import FloatingBtn from "../modules/assets/FloatingButton";
import { useQuery } from "react-query";
import { getProductDetailInfo } from "../../controllers/services/api";
import LoadingBubbleOrganisms from "../organisms/LoadingBubbleOrganisms";
import { useQueryParams } from "../../utils/useQueryParams";
import { useCheckout } from "../../controllers/services/UseCheckout";
import { useLocation } from "react-router-dom";
import ButtonAppBar from "../modules/AppBar";
import Footer from "../modules/Footer";
import { IsCartOpenContext } from "../../utils/CommonContext";
import { red } from "@material-ui/core/colors";

const theme = createTheme({
  button: {
    fontFamily: [
      "Noto Sans JP",
      "Lato",
      "游ゴシック Medium",
      "游ゴシック体",
      "Yu Gothic Medium",
      "YuGothic",
      "ヒラギノ角ゴ ProN",
      "Hiragino Kaku Gothic ProN",
      "メイリオ",
      "Meiryo",
      "ＭＳ Ｐゴシック",
      "MS PGothic",
      "sans-serif",
    ].join(","),
  },
  palette: {
    primary: {
      light: "#ff0000",
      main: "#d3d3d8",
      dark: "#c9caca",
      contrastText: "#626063",
    },
    secondary: {
      light: "#ff7f50",
      main: "#ff4500",
      dark: "#cc3600",
      contrastText: "#ffffff",
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      marginBottom: theme.spacing(2),
      width: "100%",
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  },
  typography: {
    marginBottom: theme.spacing(2),
    display: "flex",
    alignItems: "end"
  },
  typography_m: {
    // marginBottom: theme.spacing(2),
    display: "flex",
    alignItems: "end"
  },
  typography_d: {
    marginBottom: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    textDecoration: "line-through"
  },
  typography_arrow: {
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    display: "flex",
    alignItems: "center"
  },
  titleGrid: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 60,
  },
  cartGrid: {
    textAlign: "center",
  },
  bodyGrid: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  card: {
    position: 'absolute',
    paddingBottom: "0px",
    alignContent: 'center',
    backgroundColor: '#dd0000',
    opacity: 0.8,
    top: '20%',
    left: '10%',
    textAlign: 'center',
    fontWeight: 'bold',
    color: '#ffffff',
    zIndex: theme.zIndex.drawer + 1,
  },
  cardContet: {
    '&:last-child': {
      paddingBottom: 8,
    },
    padding: 8,
  },
}));

const StyledContainer = withStyles({
  root: {
    fontSize: "large",
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
})(Container);

const StyledTypography = withStyles({
  root: {
    // margin: theme.spacing(2),
    color: theme.palette.primary.contrastText,
  },
})(Typography);

const DiscountTypography = withStyles({
  root: {
    // margin: theme.spacing(2),
    color: theme.palette.primary.light,
  },
})(Typography);


const CartButton = withStyles({
  root: {
    fontSize: 14,
    padding: "12px 16px",
    width: 200,
    lineHeight: 2,
    color: theme.palette.secondary.contrastText,
    backgroundColor: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: theme.palette.secondary.light,
    },
    "&:active": {
      backgroundColor: theme.palette.secondary.dark,
    },
  },
})(Button);

const NoStockButton = withStyles({
  root: {
    fontSize: 16,
    padding: "12px 16px",
    width: 200,
    lineHeight: 2,
    color: "#ffffffff",
    backgroundColor: theme.palette.primary.dark,
  },
})(Button);

SwiperCore.use([Navigation, Pagination]);

function handleClick(event) {
  event.preventDefault();
}

function ProductPageDetail(props) {
  const classes = useStyles();
  const [quantity, setQuantity] = React.useState(1);
  const { isCartOpen, setIsCartOpen } = useContext(IsCartOpenContext);
  
  const handleCartOpen=()=> {
    setIsCartOpen(true);
  }
  const handleChange = (event) => {
    setQuantity(event.target.value);
  };

  const { Data, checkout } = props;
  const { useAddItemCheckout } = useCheckout();
  const useAddItemCheckoutMutation = useAddItemCheckout().mutate;

  const cartId = checkout[0].checkoutId;
  const product = Data[0];
  const productTitle = product.title;
  const productPrice = product.variants[0].price;
  const productCAPrice =product.variants[0].compareAtPrice;
  const disount = ( 1 -  productPrice / productCAPrice ) * 100;
  const productDescriptionHtml = product.descriptionHtml;
  const StockQuantity = product.stockQuantity;
  const images = product.images;
  const merchandiseId = product.variants[0].id;
  const lines = { merchandiseId, quantity: parseInt(quantity, 10) };

  async function AddToCart() {
    try {
      await useAddItemCheckoutMutation({ lines, cartId });
    } catch (err) {
      console.debug(err);
    }
  }

  return (
    <div>
    {
      (() => {
      // 在庫がなかったら購入ボタンを無効に
        if( StockQuantity === 0 ){
          return (
            <div>
          <Hidden xsDown>
          <StyledContainer />
          </Hidden>
  
          <Swiper
            spaceBetween={30}
            slidesPerView={1}
            loop={true}
            navigation={true}
            pagination={{ clickable: true }}
          >
            <Card
              className={classes.card}
              variant="outlined">
                <CardContent className={classes.cardContet}>
                  <Typography variant="h6">
                    この商品は売り切れました。
                  </Typography>
                </CardContent>
            </Card> 
            {images.map((img) => (
              <SwiperSlide>
                <img src={img} className="Product__img" alt="" />
              </SwiperSlide>
            ))}
          </Swiper>
  
          <Grid container justifyContent="center">
            <Grid item className={classes.titleGrid} xs={11}>
              <StyledTypography
                className={classes.typography}
                component="h1"
                variant="h5"
              >
                {productTitle}
              </StyledTypography>
            </Grid>
    
            <Grid item xs={10}>
              <StyledTypography
                className={classes.typography}
                variant="h4"
              >
                {Math.trunc(productPrice).toLocaleString()}円
              </StyledTypography>
            </Grid>
            <Grid container xs={3} 
              justifyContent="flex-end"
              alignItems="center">
              {/* 数量 */}
              <FormControl 
                variant="outlined"
                size="small"
                className={classes.formControl}
              >
                <InputLabel id="demo-simple-select-outlined-label"></InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={quantity}
                  onChange={handleChange}
                >
                  <MenuItem value={1}>1</MenuItem>
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={3}>3</MenuItem>
                  <MenuItem value={4}>4</MenuItem>
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={6}>6</MenuItem>
                  <MenuItem value={7}>7</MenuItem>
                  <MenuItem value={8}>8</MenuItem>
                  <MenuItem value={9}>9</MenuItem>
                  <MenuItem value={10}>10</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={7} 
              className={classes.cartGrid}>
              <NoStockButton
                variant="contained"
                disabled={true}
              >
                在庫がありません
              </NoStockButton>
            </Grid>
            <Grid item className={classes.bodyGrid} xs={11}>
              <div dangerouslySetInnerHTML={{ __html: productDescriptionHtml }} />
            </Grid>
          </Grid>
          </div>
          )
        } 
        // 値下げ商品
        else if ( productPrice != productCAPrice ) {
          return (
            <div>
              <Hidden xsDown>
              <StyledContainer />
              </Hidden>
              <Swiper
                spaceBetween={30}
                slidesPerView={1}
                loop={true}
                navigation={true}
                pagination={{ clickable: true }}
              >
                {images.map((img) => (
                  <SwiperSlide>
                    <img src={img} className="Product__img" alt="" />
                  </SwiperSlide>
                ))}
              </Swiper>
              <Grid container justifyContent="center">
                <Grid item className={classes.titleGrid} xs={11}>
                  <StyledTypography
                    className={classes.typography}
                    component="h1"
                    variant="h5"
                  >
                    {productTitle}
                  </StyledTypography>
                </Grid>        
                <Grid container xs={10}>
                  <StyledTypography
                    style={{ color: 'red' }}
                    variant="h6">
                    {Math.floor(disount)}％OFF
                  </StyledTypography>
                </Grid>
                <Grid container xs={10}>
                  <StyledTypography
                    className={classes.typography_m}
                    variant="caption"
                  >
                  メーカー希望小売価格
                  </StyledTypography>
                </Grid>
                <Grid container xs={10}>
                <StyledTypography
                    className={classes.typography_d}
                    variant="h6"
                  >
                  {Math.trunc(productCAPrice).toLocaleString()}円
                  </StyledTypography>
                  <StyledTypography
                    className={classes.typography_arrow}
                    variant="h6"
                  >
                  →                  
                  </StyledTypography>
                  <DiscountTypography
                    className={classes.typography}
                    variant="h4"
                  >
                    {Math.trunc(productPrice).toLocaleString()}円
                  </DiscountTypography>
                  <StyledTypography
                    className={classes.typography}
                    variant="body"
                  >
                    （税込）
                  </StyledTypography>
                </Grid>
                <Grid container xs={3} 
                  justifyContent="flex-end"
                  alignItems="center">
                  {/* 数量 */}
                  <FormControl 
                    variant="outlined"
                    size="small"
                    className={classes.formControl}
                  >
                    <InputLabel id="demo-simple-select-outlined-label"></InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={quantity}
                      onChange={handleChange}
                    >
                      <MenuItem value={1}>1</MenuItem>
                      <MenuItem value={2}>2</MenuItem>
                      <MenuItem value={3}>3</MenuItem>
                      <MenuItem value={4}>4</MenuItem>
                      <MenuItem value={5}>5</MenuItem>
                      <MenuItem value={6}>6</MenuItem>
                      <MenuItem value={7}>7</MenuItem>
                      <MenuItem value={8}>8</MenuItem>
                      <MenuItem value={9}>9</MenuItem>
                      <MenuItem value={10}>10</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={7} 
                  className={classes.cartGrid}>
                  <CartButton
                    variant="contained"
                    type="submit"
                    onClick={() => {
                      AddToCart();
                      handleCartOpen();
                    }}
                  >
                    <ShoppingCartRoundedIcon style={{ fontSize: 30 }} /> カートに入れる
                  </CartButton>
                </Grid>
                <Grid item className={classes.bodyGrid} xs={11}>
                  <div dangerouslySetInnerHTML={{ __html: productDescriptionHtml }} />
                </Grid>
                <img src={images[0]} className="Product__img" alt="" />
                <Grid item className={classes.titleGrid} xs={10}>
                  <StyledTypography
                    className={classes.typography}
                    component="h1"
                    variant="h5"
                  >
                    {productTitle}
                  </StyledTypography>
                </Grid>
                <Grid container xs={10}>
                <StyledTypography
                    className={classes.typography_m}
                    variant="caption"
                  >
                  メーカー希望小売価格(税込)
                  </StyledTypography>
                </Grid>
                <Grid container xs={10}>
                <StyledTypography
                    className={classes.typography_d}
                    variant="h6"
                  >
                  {Math.trunc(productCAPrice).toLocaleString()}円
                  </StyledTypography>
                  <StyledTypography
                    className={classes.typography_t}
                    variant="caption"
                  >
                  (税込)
                  </StyledTypography>
                  <StyledTypography
                    className={classes.typography_arrow}
                    variant="h6"
                  >
                  →                  
                  </StyledTypography>
                  <DiscountTypography
                    className={classes.typography}
                    variant="h4"
                  >
                    {Math.trunc(productPrice).toLocaleString()}円
                  </DiscountTypography>
                  <StyledTypography
                    className={classes.typography}
                    variant="body"
                  >
                    （税込）
                  </StyledTypography>
                </Grid>
                <Grid container xs={3} 
                  justifyContent="flex-end"
                  alignItems="center">
                  {/* 数量 */}
                  <FormControl 
                    variant="outlined"
                    size="small"
                    className={classes.formControl}
                  >
                    <InputLabel id="demo-simple-select-outlined-label"></InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={quantity}
                      onChange={handleChange}
                    >
                      <MenuItem value={1}>1</MenuItem>
                      <MenuItem value={2}>2</MenuItem>
                      <MenuItem value={3}>3</MenuItem>
                      <MenuItem value={4}>4</MenuItem>
                      <MenuItem value={5}>5</MenuItem>
                      <MenuItem value={6}>6</MenuItem>
                      <MenuItem value={7}>7</MenuItem>
                      <MenuItem value={8}>8</MenuItem>
                      <MenuItem value={9}>9</MenuItem>
                      <MenuItem value={10}>10</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={7} 
                  className={classes.cartGrid}>
                  <CartButton
                    variant="contained"
                    type="submit"
                    onClick={() => {
                      AddToCart();
                      handleCartOpen();
                    }}
                  >
                    <ShoppingCartRoundedIcon style={{ fontSize: 30 }} /> カートに入れる
                  </CartButton>
                </Grid>
              </Grid>
            </div>
          );
        } 
        else {
          return (
            <div>
              <Hidden xsDown>
              <StyledContainer />
              </Hidden>
              <Swiper
                spaceBetween={30}
                slidesPerView={1}
                loop={true}
                navigation={true}
                pagination={{ clickable: true }}
              >
                {images.map((img) => (
                  <SwiperSlide>
                    <img src={img} className="Product__img" alt="" />
                  </SwiperSlide>
                ))}
              </Swiper>
              <Grid container justifyContent="center">
                <Grid item className={classes.titleGrid} xs={11}>
                  <StyledTypography
                    className={classes.typography}
                    component="h1"
                    variant="h5"
                  >
                    {productTitle}
                  </StyledTypography>
                </Grid>
        
                <Grid container xs={10}>
                  <StyledTypography
                    className={classes.typography}
                    variant="h4"
                  >
                    {Math.trunc(productPrice).toLocaleString()}円
                  </StyledTypography>
                  <StyledTypography
                    className={classes.typography}
                    variant="body"
                  >
                    （税込）
                  </StyledTypography>
                </Grid>
                <Grid container xs={3} 
                  justifyContent="flex-end"
                  alignItems="center">
                  {/* 数量 */}
                  <FormControl 
                    variant="outlined"
                    size="small"
                    className={classes.formControl}
                  >
                    <InputLabel id="demo-simple-select-outlined-label"></InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={quantity}
                      onChange={handleChange}
                    >
                      <MenuItem value={1}>1</MenuItem>
                      <MenuItem value={2}>2</MenuItem>
                      <MenuItem value={3}>3</MenuItem>
                      <MenuItem value={4}>4</MenuItem>
                      <MenuItem value={5}>5</MenuItem>
                      <MenuItem value={6}>6</MenuItem>
                      <MenuItem value={7}>7</MenuItem>
                      <MenuItem value={8}>8</MenuItem>
                      <MenuItem value={9}>9</MenuItem>
                      <MenuItem value={10}>10</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={7} 
                  className={classes.cartGrid}>
                  <CartButton
                    variant="contained"
                    type="submit"
                    onClick={() => {
                      AddToCart();
                      handleCartOpen();
                    }}
                  >
                    <ShoppingCartRoundedIcon style={{ fontSize: 30 }} /> カートに入れる
                  </CartButton>
                </Grid>
                <Grid item className={classes.bodyGrid} xs={11}>
                  <div dangerouslySetInnerHTML={{ __html: productDescriptionHtml }} />
                </Grid>
                <img src={images[0]} className="Product__img" alt="" />
                <Grid item className={classes.titleGrid} xs={10}>
                  <StyledTypography
                    className={classes.typography}
                    component="h1"
                    variant="h5"
                  >
                    {productTitle}
                  </StyledTypography>
                </Grid>
                <Grid container xs={10}>
                  <StyledTypography
                    className={classes.typography}
                    variant="h4"
                  >
                    {Math.trunc(productPrice).toLocaleString()}円
                  </StyledTypography>
                  <StyledTypography
                    className={classes.typography}
                    variant="body"
                  >
                    （税込）
                  </StyledTypography>
                </Grid>
                <Grid container xs={3} 
                  justifyContent="flex-end"
                  alignItems="center">
                  {/* 数量 */}
                  <FormControl 
                    variant="outlined"
                    size="small"
                    className={classes.formControl}
                  >
                    <InputLabel id="demo-simple-select-outlined-label"></InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={quantity}
                      onChange={handleChange}
                    >
                      <MenuItem value={1}>1</MenuItem>
                      <MenuItem value={2}>2</MenuItem>
                      <MenuItem value={3}>3</MenuItem>
                      <MenuItem value={4}>4</MenuItem>
                      <MenuItem value={5}>5</MenuItem>
                      <MenuItem value={6}>6</MenuItem>
                      <MenuItem value={7}>7</MenuItem>
                      <MenuItem value={8}>8</MenuItem>
                      <MenuItem value={9}>9</MenuItem>
                      <MenuItem value={10}>10</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={7} 
                  className={classes.cartGrid}>
                  <CartButton
                    variant="contained"
                    type="submit"
                    onClick={() => {
                      AddToCart();
                      handleCartOpen();
                    }}
                  >
                    <ShoppingCartRoundedIcon style={{ fontSize: 30 }} /> カートに入れる
                  </CartButton>
                </Grid>
              </Grid>
            </div>
          );
        }
      }
    )()
    }
    </div>
    );
  }


const ProductPage = (props) => {
  const classes = useStyles();
  const queryParameters = useQueryParams();
  const handle = queryParameters.get("handle");

  const {
    isLoading: productLoading,
    data: Data,
    isError: productIsError,
    error: productError,
  } = useQuery(
    ["productDetails", handle],
    () => getProductDetailInfo(String(handle)),
    { retry: false }
  );

  const { useGetCheckout } = useCheckout();
  const {
    data: Checkout,
    isLoading: checkoutLoading,
    isError: checkoutIsError,
  } = useGetCheckout();

  if (productLoading || checkoutLoading) {
    return (
      <div>
        <Typography>データ取得中...</Typography>
        <LoadingBubbleOrganisms></LoadingBubbleOrganisms>
      </div>
    );
  }
  if (productIsError || checkoutIsError) {
    return (
      <Typography key="empty" textAlign="center">
        商品情報がありません。
      </Typography>
    );
  }

  if (!Data || !Checkout) {
    return null;
  }

  return (
    <ThemeProvider theme={theme}>
      <ButtonAppBar/>
      <Container maxWidth="md">
        <Grid container direction="column" alignItems="center">
          <Grid item xs={12}>
            <ProductPageDetail
              key={handle}
              Data={Data}
              checkout={Checkout}
              handleCartOpen={props.handleCartOpen}
            />
          </Grid>
        </Grid>
      </Container>
      <FloatingBtn />
      <Footer />
    </ThemeProvider>
  );
};

export default ProductPage;
