import {
  Box,
  Button,
  Container,
  createTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
  withStyles,
} from "@material-ui/core";
import React, { useContext } from "react";
import LineItem from "./LineItem";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useCheckout } from "../../controllers/services/UseCheckout";
import { NoSimTwoTone, PausePresentationSharp } from "@material-ui/icons";
import LoadingBubbleOrganisms from "../organisms/LoadingBubbleOrganisms";
import { IsCartOpenContext } from "../../utils/CommonContext";
import { useAuth } from "../../controllers/services/UseAuth";

const theme = createTheme({
  typography: {
    fontFamily: [
      "Noto Sans JP",
      "Lato",
      "游ゴシック Medium",
      "游ゴシック体",
      "Yu Gothic Medium",
      "YuGothic",
      "ヒラギノ角ゴ ProN",
      "Hiragino Kaku Gothic ProN",
      "メイリオ",
      "Meiryo",
      "ＭＳ Ｐゴシック",
      "MS PGothic",
      "sans-serif",
    ].join(","),
  },
  palette: {
    primary: {
      light: "#e6eae6",
      main: "#ff4500",
      dark: "#c9caca",
      contrastText: "#626063",
    },
    secondary: {
      light: "#ff7f50",
      main: "#89B929",
      dark: "#cc3600",
      contrastText: "#ffffff",
    },
  },
});

const useStyles = makeStyles({
  deliveryTime: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  points: {
    marginTop: theme.spacing(),
    width: "45ch",
    height: "3ch",
  },
  text: {
    textAlign: "center",
    fontSize: 12,
  },
  text1: {
    marginBottom: theme.spacing(1),
    textAlign: "center",
    fontSize: 12,
  },
  text3: {
    marginBottom: theme.spacing(3),
    textAlign: "center",
    fontSize: 12,
  },
  modal: {
    margin: theme.spacing(1),
  },
  modal2: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
  },
  table: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
});

const StyledTextField = withStyles({
  root: {
    color: theme.palette.primary.contrastText,
    "&:hover": {
      color: theme.palette.secondary.main,
    },
    marginTop:theme.spacing(1),
  },
})(TextField);

const StyledDialogContentText = withStyles({
  root: {
    color: theme.palette.secondary.main,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(0),
    
  },
})(DialogContentText);

const StyledSubmmitButton = withStyles({
  root: {
      backgroundColor: theme.palette.secondary.main,
      color: "#ffffff",
      "&:hover": {
        color: theme.palette.secondary.main,
      },
  },
})(Button);

const StyledTypography = withStyles((theme) => ({
  root: {
    color: theme.palette.secondary.main,
   },
  }))(Typography);

const GrayTypography = withStyles((theme) => ({
  root: {
    color: theme.palette.primary.contrastText,
    },
  }))(Typography);

const CartDetail = ({ props }) => {
  const classes = useStyles();
  const checkoutId = props.checkout.checkoutId;
  const maximumUsePoint = props.checkout.subtotalPrice * 0.1;
  // ポイント利用20％対応
  // const maximumUsePoint = props.checkout.subtotalPrice * 0.2;
  const myPoints = props.points;
  const pointsDate = props.pointsDate;

  const { 
    usePointsCheckout,
    useDeliveryTimeCheckout,
    useDeletePointsCheckout,
    useAllRemoveItemCheckout
  } = useCheckout();
  const usePointsCheckoutMutation = usePointsCheckout().mutate;
  const useDeliveryTimeCheckoutMutation = useDeliveryTimeCheckout().mutate;
  const useDeletePointsCheckoutMutation = useDeletePointsCheckout().mutate;
  const useRemoveAllItemCheckoutMutation = useAllRemoveItemCheckout().mutate;

  // ポイント有効期限
  pointsDate.setFullYear(pointsDate.getFullYear());
  function formatDate(dt) {
    let y = dt.getFullYear();
    let m = ('00' + (dt.getMonth()+1)).slice(-2);
    let d = ('00' + dt.getDate()).slice(-2);
    return (y + '年' + m + '月' + d + '日');
  };

  const { isCartOpen, setIsCartOpen } = useContext(IsCartOpenContext);
  const handleCartClose = () => {
    setIsCartOpen(false);
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (num) => {
    setOpen(num);
  };

  const handleClose = (event, reason) => {
    if (reason && reason == "backdropClick") 
        return;
    setOpen(false);
  }

  const [deliveryTime, setDeliveryTime] = React.useState("指定なし");
  const handleChange2 = (event) => {
    setDeliveryTime(event.target.value);
  };

  const validationSchema = Yup.object({
    points: Yup.number()
      .typeError("ポイントは半角数字で入力してください")
      .test("points", "合計金額の10％以内でご利用ください", function (points) {
        if (maximumUsePoint < points) return false;
        return true;
      })
      // ポイント20％対応
      // .test("points", "合計金額の20％以内でご利用ください", function (points) {
      //   if (maximumUsePoint < points) return false;
      //   return true;
      // })
      .test("points", "ポイントが不足しています", function (points) {
        if (myPoints < points) return false;
        return true;
      }),
  });

  const { values, handleChange, handleSubmit, isValid, errors } = useFormik({
    enableReinitialize: true,
    initialValues: {
      cartId: checkoutId ? checkoutId : "",
      pointCode: "",
      points: "",
      deliveryTime: deliveryTime,
    },
    validationSchema: validationSchema,
    onSubmit: onSubmit,
  });

  async function onSubmit() {
    try {
      await usePointsCheckoutMutation(values);
      props.reloadCheckout();
    } catch (err) {
      console.debug(err);
    }
  };

  const delivelyTimeApplyButton = async () => {
    try{
      await useDeliveryTimeCheckoutMutation(values)
      console.log(values);
    } catch (err) {
      console.debug(err);
    }
  };

  const deletePointsButton = async () => {
    try{
      await useDeletePointsCheckoutMutation(values.cartId)
    } catch (err) {
      console.debug(err);
    }
  };

  function createData(id, amount) {
    return { id, amount };
  }
  const rows = [
    createData('小計', props.checkout.subtotalPrice),
    createData('利用ポイント数', - (props.checkout.subtotalPrice-props.checkout.totalPrice)),
    createData('合計金額', Math.floor(props.checkout.totalPrice)),
  ];

  // Dartの関数呼び出し
  function callDart() {
    try {
      getData.postMessage(props.checkout.webUrl);
    } catch (e) {
      window.open(props.checkout.webUrl);
    }
  }

  function openCheckout() {
    callDart();
  }

  const poitUse = `下記にご利用ポイント数をご入力下さい。`
  const pointMsg = `商品合計金額の10％【${Math.floor( maximumUsePoint )}pt】が上限です`;
  // ポイント20％対応
  // const pointMsg = `商品合計金額の20％【${Math.floor( maximumUsePoint )}pt】が上限です`;
  const pointMaxMsg = `所持ポイント【${Math.floor( myPoints )}pt】が上限です`;
  const lineIds = props.checkout.lineItems.map((line_item) => {
    return line_item.node.id;
  });

  let line_items = props.checkout.lineItems.map((line_item) => {
    return (
      <LineItem
        cartId={checkoutId}
        key={line_item.node.id.toString()}
        line_item={line_item}
      />
    );
  });

  return (
    <div className={`Cart ${isCartOpen ? "Cart--open" : ""}`}>
      <header className="Cart__header">
        <h2>カートを見る</h2>
        <button onClick={handleCartClose} className="Cart__close">
          ×
        </button>
        <StyledTypography>ご利用可能ポイント： {myPoints.toLocaleString()}pt</StyledTypography>
        <StyledTypography>ポイント有効期限： {formatDate(pointsDate)}</StyledTypography>
        <GrayTypography>合計金額4,980円以上のご購入で送料無料</GrayTypography>
      </header>
      <ul className="Cart__line-items">{line_items}</ul>
      <footer className="Cart__footer">
        <div className="Cart-info clearfix">
          <div className="Cart-info__total Cart-info__small">小計（税込）</div>
          <div className="Cart-info__pricing">
            <span className="pricing">{props.checkout.subtotalPrice} 円</span>
          </div>
        </div>
        <div className="Cart-info clearfix">
          <div className="Cart-info__total Cart-info__small">（うち消費税）</div>
          <div className="Cart-info__pricing">
            <span className="pricing　Cart-info__small">{Math.floor(props.checkout.totalTax)} 円</span>
          </div>
        </div>
        <div className="Cart-info clearfix">
          <div className="Cart-info__total Cart-info__small">合計金額（税込）</div>
          <div className="Cart-info__pricing">
            <span className="pricing">{Math.floor(props.checkout.totalPrice)} 円</span>
          </div>
        </div>
     <div className="Cart-point">
      <Button
        variant="outlined"
        color="primary"
        fullWidth="true"
        style={{
          marginTop:theme.spacing(2), 
          color:theme.palette.secondary.main
        }}
        onClick={() => handleClickOpen(0)}>
        配送時間を指定する
      </Button>
      {/* <Button
        variant="outlined"
        color="primary"
        fullWidth
        style={{
          marginTop:theme.spacing(2), 
          color:theme.palette.secondary.main
        }}
        onClick={handleClickOpen}>
        ポイントを使用する
      </Button> */}
      </div>

      <Dialog 
        open={(open === 0)}
        onClose={handleClose}
        >
        <DialogContent
          className={classes.modal}>
            <StyledDialogContentText>
              お届け希望時間帯をご選択ください。
            </StyledDialogContentText>

            <FormControl
              fullWidth
              className={classes.deliveryTime}
              size="small"
              color="secondary"
              value={values.deliveryTime}
              onChange={handleChange}
            >
            <InputLabel id="simple-select-label">お届け希望時間帯</InputLabel>
            <Select
              labelId="simple-select-label"
              id="deliveryTime"
              value={deliveryTime}
              onChange={handleChange2}
            >
              <MenuItem value={"指定なし"}>指定なし</MenuItem>
              <MenuItem value={"午前中(8時-12時)"}>午前中(8時-12時)</MenuItem>
              <MenuItem value={"14時-16時"}>14時-16時</MenuItem>
              <MenuItem value={"16時-18時"}>16時-18時</MenuItem>
              <MenuItem value={">18時-21時"}>18時-21時</MenuItem>
              <MenuItem value={"19時-21時"}>19時-21時</MenuItem>
            </Select>
            </FormControl>
          <DialogActions>
            <Button
              onClick={handleClose} 
              style={{color:theme.palette.primary.contrastText}}>
              キャンセル
            </Button>
            <StyledSubmmitButton
              onClick={() => {
                delivelyTimeApplyButton(values);
                handleClose();
              }}
              >
              確定する
            </StyledSubmmitButton>
          </DialogActions>
        </DialogContent>
      </Dialog>
      <div >
        <button
          className="Cart__checkout button"
          disabled={!isValid}
          onClick={() => handleClickOpen(1)}
        >
          ご 購 入 手 続 き
        </button>
      </div>
      <Dialog 
      open={(open === 1)}
      onClose={handleClose}
      >
      <DialogContent
        className={classes.modal2}>
          <hr></hr>
          <div style={{ textAlign: "center" }}>
            <GrayTypography>ご利用可能ポイント： {myPoints.toLocaleString()}pt</GrayTypography>
            <GrayTypography>ポイント有効期限： {formatDate(pointsDate)}</GrayTypography>
          </div>
          <hr></hr>
          <StyledDialogContentText>
            {/* 改行を入れる */}
            <div style={{
              whiteSpace: "pre-line", 
              textAlign: "center" }}>
            {poitUse}
            </div>
          </StyledDialogContentText>
          
          <StyledTextField
            fullWidth
            id="points"
            label="ご利用ポイント数を入力"
            // variant="outlined"
            color="secondary"
            size="small"
            value={values.points || ""}
            onChange={handleChange}
            error={Boolean(errors.points)}
            helperText={errors.points}
          />
          {(
            () => {
              if( myPoints < maximumUsePoint ){
                return(<Typography className={classes.text1}>{pointMaxMsg}</Typography>)
              } else {
                return(<Typography className={classes.text1}>{pointMsg}</Typography>)
              }
            })()
          }
          <form onSubmit={handleSubmit}>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => {
              deletePointsButton(values.cartId);
              handleClose();
            }}
            style={{color:theme.palette.primary.contrastText}}>
            ショップに戻る
          </Button>
          <StyledSubmmitButton
            // onClick={handleClose}
            type="submit"
            >
            利用ポイント数を確定
          </StyledSubmmitButton>
          </DialogActions>
          </form>
          <TableContainer component={Paper} className={classes.table}>
            <Table
              size="small" 
              aria-label="a dense table"
              style={{border: 'none'}}>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      {row.id}
                    </TableCell>
                    <TableCell align="right">{row.amount} 円</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Typography className={classes.text}>ポイント利用後の合計金額が4,980円以上で送料無料</Typography>
          <Typography className={classes.text3}>※送料無料商品は除く</Typography>
          <button
          className="Cart__checkout button"
          disabled={!isValid}
          onClick={ () => {
            openCheckout()
            useRemoveAllItemCheckoutMutation()
            handleClose()
          }}
        >
          ご 購 入 手 続 き
        </button>
      </DialogContent>
    </Dialog>
            {/* <div className="Cart-point">
              <StyledSubmmitButton
                variant="outlined"
                fullWidth
                type="submit"
                >
                ポイント利用で購入手続きへ
              </StyledSubmmitButton>
            </div> */}
      </footer>
    </div>
  );
};

function Cart(props) {
  const points = props.points;
  const pointsDate = new Date(props.date);
  const { useGetCheckout } = useCheckout();
  const { useGetAuth } = useAuth();
  const {
    data: Checkout,
    isLoading: checkoutIsLoading,
    isFetching: checkoutIsFetching,
    isError: checkoutIsError,
    refetch: reloadCheckout,
  } = useGetCheckout();
  const {
    data: User,
    isLoading: userIsLoading,
    isError: userIsError,
  } = useGetAuth();

  if (checkoutIsLoading || userIsLoading) {
    return (
      <div>
        {/* <Typography>データ取得中...</Typography>
      <LoadingBubbleOrganisms></LoadingBubbleOrganisms> */}
      </div>
    );
  }
  if (checkoutIsError || userIsError) {
    return <div>
    </div>;
  }
  if (Checkout && User) {
    const checkout = {
      checkoutId: Checkout[0].checkoutId,
      webUrl: Checkout[0].webUrl,
      lineItems: Checkout[0].lineItem,
      subtotalPrice: Checkout[0].lineItemPrice,
      totalTax: Checkout[0].totalTax,
      totalPrice: Checkout[0].totalPrice,
    };

   if ( checkout.lineItems == undefined ) {
    return  <div>
    </div>;
   }
  
   if ( points == undefined ) {
    return  <div>
    </div>;
   }

    const Customer_Token = User.customer_token;
    return (
      <div>
        <CartDetail
          props={{ checkout, points, pointsDate, Customer_Token }}
          key={checkout.checkoutId}
          reloadCheckout={reloadCheckout}
        />
      </div>
    );
  }
}

export default Cart;
